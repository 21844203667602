import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BruudToastrService } from '@app/services/message.service';
import { isValidationErrors } from '@shared/validation/helpers/catch-validation-errors';
import { MY_CLOUD_HTTP_CONFIG, MyCloudHttpConfig } from '@app/models/config/mc-http-config.interface';
import { Location } from '@angular/common';


@Injectable()
export class ReponseToastInterceptor implements HttpInterceptor {
   private showSuccessGetToast = false;
   private showSuccessNonGetToast = false;
   private showValidationErrorToast = false;
   private showPermissionErrorToast = false;

   constructor(private location: Location, private messageService: BruudToastrService, @Optional() @Inject(MY_CLOUD_HTTP_CONFIG) httpConfig: MyCloudHttpConfig) {
      if (httpConfig) {
         this.showSuccessGetToast = httpConfig.showSuccessGetToast;
         this.showSuccessNonGetToast = httpConfig.showSuccessNonGetToast;
         this.showValidationErrorToast = httpConfig.showValidationErrorToast;
         this.showPermissionErrorToast = httpConfig.showPermissionErrorToast;
      }
   }

   public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (req.headers.has('X-MY-CLOUD-No-Response-Toast')) { //skip response toast interceptor if header exists
         return next.handle(req);
      }

      return next.handle(req).pipe(
         tap(event => {
            if (event.type === HttpEventType.ResponseHeader || event.type === HttpEventType.Response) {
               const customSuccessToast = req.headers.get('X-MY-CLOUD-Custom-Success-Toast');
               this.showHttpSuccess(req.method.toUpperCase(), customSuccessToast);
            }
         }, error => {
            error instanceof HttpErrorResponse
               ? this.showHttpError(req.method.toUpperCase(), error)
               : this.showGenericError('');
         })
      );
   }

   private showHttpSuccess(method: string, customSuccessToast: string) {
      if (customSuccessToast) {
         return this.messageService.showTranslatedSuccess(customSuccessToast); //musts autodismiss after 3sec
      }

      if (method === 'GET' && !this.showSuccessGetToast) {
         return;
      }
      if (method !== 'GET' && !this.showSuccessNonGetToast) {
         return;
      }

      this.messageService.showTranslatedSuccess("BCKOFF_ACTION_EXEC_OK");//musts autodismiss after 3sec
   }

   private showHttpError(method: string, error: HttpErrorResponse): void {
      switch (error.status) {
         case 400:
            this.showBadRequestError(method, error.error);
            break;
         case 401:
         case 403:
            this.showPermissionError();
            break;
         case 404:
         case 500:
         default:
            this.showGenericError();
      }
   }

   /**
    * show normal Validation errors (no toasters)
    * @param {string} method method type of the request
    * @param {any} errorbody body to check if has validation errors
    * @returns {void}
    */
   private showBadRequestError(method: string, errorbody: any): void {
      if (!this.showValidationErrorToast) {
         return;
      }

      if ((method === 'POST' || method === 'PUT' || method === 'DELETE') && isValidationErrors(errorbody)) {
         this.messageService.showTranslatedError("GENERAL_ERROR_MESSAGE");
      } else {
         this.showGenericError(method);
      }
   }

   /**
    * generic not authorized message --> redirect to last page in history
    * @returns {void}
    */
   private showPermissionError(): void {
      if (!this.showPermissionErrorToast) {
         return;
      }

      this.messageService.showTranslatedError("NO_ACCESS");
      // this.location.back();
   }

   /**
    * generic 'contact support' --> redirect to last page in history
    * @param {string} method method type of the request
    * @returns {void}
    */
   private showGenericError(method?: string): void {
      switch (method) {
         case 'GET':
            this.messageService.showTranslatedError("GENERAL_ERROR_MESSAGE");
            break;
         case 'PUT':
         case 'POST':
         case 'DELETE':
            this.messageService.showTranslatedError("GENERAL_ERROR_MESSAGE");
            break;
         default:
            this.messageService.showTranslatedError("GENERAL_ERROR_MESSAGE");
      }

      // this.location.back();
   }
}
