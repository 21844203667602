<ul>
   <li id="menu-item-my-account">
      <a [routerLink]="'/ng/account/overview'" [class.active]="activeRoute.includes('/ng/account/overview')">
         <span> {{'BCKOFF_MNU_HOME' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-customers" *ngIf="customerIsPartner">
      <a [routerLink]="'/ng/account/customers'" [class.active]="activeRoute.includes('/ng/account/customers')">
         <span> {{'BCKOFF_MNU_CUSTOMERS' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-products">
      <a [routerLink]="'/ng/products/overview'" [class.active]="activeRoute.includes('/ng/products/overview')">
         <span [innerHtml]="'BCKOFF_MNU_PRODUCT_OVERVIEW' | translate"></span>
      </a>
   </li>

   <li id="menu-item-invoices">
      <a [routerLink]="'/ng/documents/overview'" [class.active]="activeRoute.includes('/ng/documents/overview')">
         <span> {{'BCKOFF_MNU_INVOICES_OVERVIEW' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-separator"></li>

   <li id="menu-item-domains">
      <a [routerLink]="'/ng/domain/overview'" [class.active]="activeRoute.includes('/ng/domain')">
         <span> {{'BCKOFF_MNU_DOMAINS' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-sites">
      <a [routerLink]="'/ng/shared-hosting/overview'"
         [class.active]="activeRoute.includes('/ng/shared-hosting') || activeRoute.includes('/ng/linux') || activeRoute.includes('/ng/o365')">
         <span> {{'BCKOFF_MNU_SITES' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-servers">
      <a [routerLink]="'/ng/data-center/cloud/overview'" [class.active]="activeRoute.includes('/ng/data-center/cloud/overview')">
         <span> {{'BCKOFF_MNU_SERVERS' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-separator"></li>

   <li id="menu-item-promotions">
      <a [routerLink]="'/ng/promotions/overview'" [class.active]="activeRoute.includes('/ng/promotions/overview')">
         <span> {{'BCKOFF_MNU_PROMO' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-subscriptions">
      <a [routerLink]="'/ng/subscriptions/overview'" [class.active]="activeRoute.includes('/ng/subscriptions')">
         <span> {{'BCKOFF_MNU_ORDERS' | translate}} </span>
      </a>
   </li>

   <li id="menu-item-separator"></li>

   <li id="menu-item-tickets">
      <a [routerLink]="'/ng/tickets'" [class.active]="activeRoute.includes('/ng/tickets')">
         <span> {{'BCKOFF_MNU_TICKETS_NEW' | translate}} </span>
      </a>
   </li>
</ul>