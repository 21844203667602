import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/guards/auth.guard';
import { MaintenanceGuard } from './core/guards/maintenance.guard';
import { MaintenanceComponent } from '@app/components/maintenance/maintenance.component';
import { NotFoundComponent } from '@app/components/not-found/not-found.component';
import { CloudErpSsoComponent } from '@app/components/cloud-erp-sso.component';
import { CloudErpSsoGuard } from '@app/guards/cloud-erp-sso.guard';
import { ProfileResolverService } from '@app/services/profile-resolver.service';
import { ModalLoginComponent } from '@app/components/modal-login/modal-login.component';

const routes: Routes = [
   {
      path: '',
      redirectTo: '/ng/account/overview',
      pathMatch: 'full'
   },
   {
      path: 'modal-login',
      component: ModalLoginComponent,
   },
   {
      path: 'ng/maintenance',
      canActivate: [MaintenanceGuard],
      component: MaintenanceComponent
   },
   {
      path: 'ng',
      canActivate: [MaintenanceGuard],
      children:
         [
            {
               path: 'okta',
               loadChildren: () => import('./modules/okta-login/okta.module').then(module => module.OktaModule)
            },
            {
               path: 'sso',
               canActivate: [CloudErpSsoGuard],
               component: CloudErpSsoComponent,
            },
            {
               path: 'origin',
               loadChildren: () => import('./modules/origin-sso/origin-sso.module').then(module => module.OriginSsoModule)
            },
            {
               path: '',
               canActivateChild: [AuthGuard],
               resolve: { data: ProfileResolverService },
               children: [
                  {
               path: 'account',
               loadChildren: () => import('./modules/account/account.module').then(module => module.AccountModule)
            },
            {
                     path: 'data-center',
                     loadChildren: () => import('./modules/data-center/data-center.module').then(module => module.DataCenter)
                  },
                  {
               path: 'documents',
               loadChildren: () => import('./modules/documents/documents.module').then(module => module.DocumentsModule)
            },
            {
               path: 'domain',
               loadChildren: () => import('./modules/domain/domain.module').then(module => module.DomainModule)
            },
            {
                     path: 'linux',
                     loadChildren: () => import('./modules/linux/linux.module').then(module => module.LinuxModule)
                  },
                  {
                     path: 'm365',
                     loadChildren: () => import('./modules/m365/m365.module').then(module => module.M365Module)
                  },
                  {
                     path: 'products',
                     loadChildren: () => import('./modules/products/products.module').then(module => module.ProductsModule)
                  },
                  {
                     path: 'promotions',
                     loadChildren: () => import('./modules/promotions/promotion.module').then(module => module.PromotionModule)
                  },
                  {
                     path: 'shared-hosting',
                     loadChildren: () => import('./modules/shared-hosting/shared-hosting.module').then(module => module.SharedHostingModule)
                  },
                  {
               path: 'subscriptions',
               loadChildren: () => import('./modules/subscriptions/subscription.module').then(module => module.SubscriptionsModule)
            },
            {
               path: 'tickets',
                     loadChildren: () => import('./modules/contacting/contacting.module').then(module => module.ContactingModule)
                  }
               ]
            }
         ]
   },
   {
      path: '**',
      component: NotFoundComponent
   }
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
   exports: [RouterModule]
})
export class AppRoutingModule { }
